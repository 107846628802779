<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpx-4 cpy-2">
        <span class="font-title fs-4">販売設定</span>
      </div>
      <form class="cpx-15" id="create-content" autocomplete="off">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >サンクスページURL（カード決済後）<span class="textMust"
              >（必須）</span
            >
          </label>
          <div class="cp-4 w-100">
            <input class="form-input col-sm-12" v-model="url_payment" />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >サンクスページURL（銀行申込後）<span class="textMust"
              >（必須）</span
            ></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="url_register"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >利用する支払方法</label
          >
          <div class="cp-4 w-100">
            <b-form-group v-slot="{}">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="default_payment"
                :options="optionsDefault_payment"
                name="flavour-1"
                class="mb-0"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >デフォルト月額設定で選択させるペイメント</label
          >
          <div class="cp-4 w-100">
            <b-form-group v-slot="{}">
              <b-form-checkbox-group
                id="checkbox-group-12"
                v-model="select_payment"
                :options="optionsPayment"
                name="flavour-12"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >デフォルト税率</label
          >
          <div class="cp-4 w-100">
            <input
              v-model="tat_rax"
              type="number"
              class="form-control col-sm-12 form-input"
              style="ont-weight: 550; color: black; font-size: 0.875rem"
              min="0"
              @keyup="checkValidateNumber($event)"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >デフォルト送料</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input w-100"
              type="number"
              v-model="default_shipping"
              min="0"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
          </div>
        </div>
        <!-- <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold pl-0">デフォルト著者</label>
          <b-form-select
            class="form-input col-sm-12"
            id="select-auth"
            style="font-weight: 550; color: black; font-size: 0.875rem"
            v-model="selected_author"
            :options="listAuthorOptions"
          ></b-form-select>
        </div>
        <div class="row mb-3 row-input d-flex">
          <div class="">
            <label class="col-sm-12 font-weight-bold pl-0"
              >デフォルト重複設定（オンライン）
            </label>
          </div>
          <div class="col-sm-12" style="padding-left: 0px">
            <label
              v-bind:class="{ isSelected: index === isChecked }"
              v-on:click="ClickContentChecked(index, $event)"
              v-for="(item, index) in optionLive"
              :key="index"
              class="custom-label col-sm-3 col-lg-1 text-nowrap"
              @click="setValueLabel(item.value)"
            >
              {{ item.text }}
            </label>
          </div>
        </div>
        <div class="row mb-3 row-input d-flex">
          <div class="">
            <label class="col-sm-12 font-weight-bold pl-0"
              >デフォルト重複設定（物販）
            </label>
          </div>
          <div class="col-sm-12" style="padding-left: 0px">
            <label
              v-bind:class="{ isSelected: index === isSelect }"
              v-on:click="ClickContentSelected(index, $event)"
              v-for="(item, index) in optionProductSales"
              :key="index"
              class="custom-label col-sm-3 col-lg-1 text-nowrap"
              @click="setValueProductSales(item.value)"
            >
              {{ item.text }}
            </label>
          </div>
        </div> -->
      </form>
      <div class="d-flex justify-content-center mt-4 py-4">
        <b-button
          v-on:click.prevent="CreateSalesSetting()"
          :disabled="isLoading"
          class="mr-4 btn-color"
          variant="success"
          >保存</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      url_register: "",
      url_payment: "",
      category_ID: "",
      isLoading: false,
      optionsDefault_payment: [
        { text: "クレジットカード", value: 0, disabled: true },
        { text: "paypal", value: 1, disabled: true },
        { text: "銀行振込", value: 2 },
      ],
      optionsPayment: [
        { text: "クレジットカード", value: 0, disabled: true },
        { text: "paypal", value: 1, disabled: true },
      ],
      default_payment: [],
      select_payment: [],
      tat_rax: "",
      default_shipping: "",
      listAuthorOptions: [],
      selected_author: "",
      isChecked: "",
      optionLive: [
        { value: 0, text: "許可" },
        { value: 1, text: "禁止" },
      ],
      valueDefaultLive: "",
      isSelect: "",
      valueProductSales: "",
      optionProductSales: [
        { value: 0, text: "許可" },
        { value: 1, text: "禁止" },
      ],
      id: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    const request = {
      shop_id: this.shop_id,
    };
    this.getListAuthorInShop(request);
    this.getSaleSettingAdmin(request.shop_id);
    this.getShopByIdForAdmin(request.shop_id);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters([
      "listAuthor",
      "saleSettingById",
      "success",
      "message",
      "error",
      "detailShop",
    ]),
  },
  watch: {
    saleSettingById() {
      this.id = this.saleSettingById.id;
      this.url_payment = this.saleSettingById.url_page_thanks_card;
      this.url_register = this.saleSettingById.url_page_thanks_transfer;
      this.default_payment = this.saleSettingById.payment_default;
      this.select_payment = this.saleSettingById.subscription_default;
      this.tat_rax = this.saleSettingById.tax_default;
      this.default_shipping = this.saleSettingById.consultation_fee_default;
      this.selected_author = this.saleSettingById.author;
      this.isChecked = this.saleSettingById.duplicate_default_online;
      this.valueDefaultLive = this.saleSettingById.duplicate_default_online;
      this.isSelect = this.saleSettingById.duplicate_default_sale;
      this.valueProductSales = this.saleSettingById.duplicate_default_sale;
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    listAuthor() {
      if (this.listAuthor.length > 0) {
        this.listAuthorOptions = this.listAuthor.map((item) => ({
          text:
            item && item.name && item.name.length > Constants.MAX_LENGTH
              ? item.name.substr(0, Constants.MAX_LENGTH) + "..."
              : item.name,
          value: item.id.toString(),
        }));
      } else {
        this.listAuthorOptions = [
          { text: "リストは空です", value: null, disabled: true },
        ];
      }
    },
    detailShop() {
      const dataSettingPayment = this.detailShop;
      if (dataSettingPayment.stripe_id && dataSettingPayment.stripe_secret) {
        this.optionsDefault_payment[0].disabled = false;
        this.optionsPayment[0].disabled = false;
      }
      if (dataSettingPayment.paypal_id && dataSettingPayment.paypal_secret) {
        this.optionsDefault_payment[1].disabled = false;
        this.optionsPayment[1].disabled = false;
      }
    },
  },
  methods: {
    ...mapActions({
      createSaleSetting: "createSaleSetting",
      getSaleSettingAdmin: "getSaleSettingAdmin",
      getListAuthorInShop: "getListAuthorInShop",
      getShopByIdForAdmin: "getShopByIdForAdmin",
    }),
    async CreateSalesSetting() {
      if (this.url_payment && !this.url_payment.match(Constants.REGEX_URL)) {
        return this.$toasted.error(
          "ありがとうページのURLの形式が正しくありません"
        );
      }
      if (this.url_register && !this.url_register.match(Constants.REGEX_URL)) {
        return this.$toasted.error(
          "ありがとうページのURLの形式が正しくありません1"
        );
      }
      this.isLoading = true;
      const shop_id = this.shop_id;
      const formData = {
        id: this.id,
        shop_id: shop_id,
        url_page_thanks_card: this.url_payment,
        url_page_thanks_transfer: this.url_register,
        payment_default: this.default_payment,
        subscription_default: this.select_payment,
        tax_default: parseInt(this.tat_rax),
        consultation_fee_default: parseInt(this.default_shipping),
        author: this.selected_author,
        duplicate_default_online: this.valueDefaultLive,
        duplicate_default_sale: this.valueProductSales,
      };
      const dataReturn = await this.$store.dispatch(
        "createSaleSetting",
        formData
      );
      if (dataReturn.success) {
        this.$router.go(-1);
      } else {
        this.$toasted.error(this.message);
        this.isLoading = false;
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    ClickContentChecked(index) {
      if (this.isChecked === index) {
        this.isChecked = null;
      } else {
        this.isChecked = index;
      }
    },
    ClickContentSelected(index) {
      if (this.isSelect === index) {
        this.isSelect = null;
      } else {
        this.isSelect = index;
      }
    },
    setValueLabel(value) {
      this.valueDefaultLive = value;
    },
    setValueProductSales(value) {
      this.valueProductSales = value;
    },
    checkValidateNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+-$/.test(char)) return true;
      else e.preventDefault();
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
</style>
